import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { Trans } from "react-i18next";
import "./styles/Sidebar.css";
import Cookies from "js-cookie";
import config from "../../functions/config";
import axios from "axios";

const Sidebar = () => {
  const [menuState, setMenuState] = useState({});
  const location = useLocation();

  const ID = Cookies.get("user_id");

  const user = Cookies.get("role");
  var is_company = false;
  if (user === "Company" || user === "Staff") {
    is_company = true;
  }

  const [modules, setModules] = useState({});

  const fetchModules = () => {
    axios
      .get(`${config.base_url}/get_modules/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          const modules = res.data.modules;

          setModules(modules);
        }
      })
      .catch((err) => {
        console.log("MODULES ERROR==", err);
      });
  };

  useEffect(() => {
    fetchModules();
  }, []);

  const toggleMenuState = (menu) => {
    setMenuState((prevState) => {
      const newState = { ...prevState };
      Object.keys(newState).forEach((key) => {
        newState[key] = false;
      });
      newState[menu] = !prevState[menu];
      return newState;
    });
  };

  const onRouteChanged = () => {
    document.querySelector("#sidebar").classList.remove("active");
    setMenuState({});

    const dropdownPaths = [
      { path: "/company_home", state: "appsMenuOpen" },
      { path: "/company_staffs", state: "companyStaffMenuOpen" },
      { path: "/Items", state: "ItemsMenuOpen" },
     
    ];

    dropdownPaths.forEach((obj) => {
      if (isPathActive(obj.path)) {
        setMenuState((prevState) => ({ ...prevState, [obj.state]: true }));
      }
    });
  };

  useEffect(() => {
    onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", function () {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }, [location]);

  const isPathActive = (path) => {
    return location.pathname.startsWith(path);
  };

  return (
    <nav className="sidebar sidebar-offcanvas" id="sidebar">
      <ul className="nav">
        <li
          className={
            isPathActive("/company_home") ? "nav-item active" : "nav-item"
          }
        >
          <Link className="nav-link" to="/company_home">
            <span className="menu-title">
              <Trans>Dashboard</Trans>
            </span>
            <i className="mdi mdi-home menu-icon mb-0"></i>
          </Link>
        </li>
        {is_company ? (
          <li
            className={
              isPathActive("/company_staffs") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.companyStaffMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("companyStaffMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Staff</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-account menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.companyStaffMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/company_staffs/staff_requests")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/company_staffs/staff_requests"
                  >
                    <Trans>Staff Request</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/company_staffs/all_staffs")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/company_staffs/all_staffs"
                  >
                    <Trans>All Staff</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        ) : null}
         <li
            className={
              isPathActive("/company_staffs") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.companyStaffMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("companyStaffMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Cash and Bank</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.companyStaffMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/banking/bankinglistout")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/banking/bankinglistout"
                  >
                    <Trans>Offline Banking</Trans>
                  </Link>
                </li>
                <li className="nav-item">
                  {" "}
                  
                </li>

                  
                   <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/banklist") ? "nav-link active" : "nav-link"
                    }
                    to="/banklist"
                  >
                    <Trans>Bank Holder</Trans>
                  </Link>
                </li>


              </ul>
            </Collapse>
          </li>   
        
        

        
          <li
            className={
              isPathActive("/Items") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.ItemsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("ItemsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Item</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/Items")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/Items"
                  >
                    <Trans>Items</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>

            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/PriceList")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/PriceList"
                  >
                    <Trans>Price Lists</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/stock_adjustment") ? "nav-link active" : "nav-link"
                    }
                    to="/stock_adjustment"
                  >
                    <Trans>Stock Adjustment</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


          </li>
          <li
            className={
              isPathActive("/customers") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("customerMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Sales</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/customers") ? "nav-link active" : "nav-link"
                    }
                    to="/customers"
                  >
                    <Trans>Customers</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


<Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/invoice") ? "nav-link active" : "nav-link"
                    }
                    to="/invoice"
                  >
                    <Trans>Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>


            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/ret_invoice") ? "nav-link active" : "nav-link"
                    }
                    to="/ret_invoice"
                  >
                    <Trans>Retainer Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/Estimate") ? "nav-link active" : "nav-link"
                    }
                    to="/Estimate"
                  >
                    <Trans>Estimate</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.customerMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/recurringinvoice_listoutpage") ? "nav-link active" : "nav-link"
                    }
                    to="/recurringinvoice_listoutpage"
                  >
                    <Trans>Recurring Invoice</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              isPathActive("/vendor") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.VendorMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("VendorMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Purchase</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/vendor")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/vendor"
                  >
                    <Trans>Vendor</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            <Collapse in={menuState.VendorMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/expense") ? "nav-link active" : "nav-link"
                    }
                    to="/expense"
                  >
                    <Trans>Expense</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            </li>
          <li
            className={
              isPathActive("/listoutemployee") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.ItemsMenuOpen
                  ? "nav-link menu-expanded"
                  : "nav-link"
              }
              onClick={() => toggleMenuState("ItemsMenuOpen")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Employee</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-package menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.ItemsMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  {" "}
                  <Link
                    className={
                      isPathActive("/listoutemployee")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    to="/listoutemployee"
                  >
                    <Trans>Employee</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li
            className={
              isPathActive("/manualjournal") ? "nav-item active" : "nav-item"
            }
          >
            <div
              className={
                menuState.customerMenuOpen ? "nav-link menu-expanded" : "nav-link"
              }
              onClick={() => toggleMenuState("accountingSubmenu")}
              data-toggle="collapse"
            >
              <span className="menu-title">
                <Trans>Accounting</Trans>
              </span>
              <i className="menu-arrow"></i>
              <i className="mdi mdi-file-chart menu-icon mb-0"></i>
            </div>
            <Collapse in={menuState.accountingSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/chart_accounts") ? "nav-link active" : "nav-link"
                    }
                    to="/chart_accounts"
                  >
                    <Trans>Chart of Accounts</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>



            <Collapse in={menuState.accountingSubmenu}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={
                      isPathActive("/manualjournal") ? "nav-link active" : "nav-link"
                    }
                    to="/manualjournal"
                  >
                    <Trans>Manual Journal</Trans>
                  </Link>
                </li>
              </ul>
            </Collapse>
            </li>

      </ul>
    </nav>
  );
};

export default Sidebar;
