import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import * as XLSX from 'xlsx'; 
import "./styles/customer.css";
import "bootstrap/dist/css/bootstrap.css";



function StockAdjustment() {
  const navigate = useNavigate();
  const exportToExcel = () => {
    document.querySelectorAll(".descTooltips").forEach((el) => {
      el.style.display = "none";
    });
    document.querySelectorAll(".descExport").forEach((el) => {
      el.style.display = "block";
    });
  
    const table = document.getElementById("customersTable"); // Update the ID here
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "StockAdjustment.xlsx");
  
    document.querySelectorAll(".descTooltips").forEach((el) => {
      el.style.display = "block";
    });
    document.querySelectorAll(".descExport").forEach((el) => {
      el.style.display = "none";
    });
  };
  

  
  function filterTable(columnIndex, filterValue) {
    var table = document.getElementById("customersTable"); // Correct table ID
    var rows = table.getElementsByTagName("tr");
  
    for (var i = 1; i < rows.length; i++) {
      var statusCell = rows[i].getElementsByTagName("td")[columnIndex]; // Use the correct column index
  
      if (statusCell) {
        if (
          filterValue === "all" ||
          statusCell.textContent.trim().toLowerCase() === filterValue
        ) {
          rows[i].style.display = ""; // Show row
        } else {
          rows[i].style.display = "none"; // Hide row
        }
      }
    }
  }
  
  function sortTableByDate(row) {
    let table = document.getElementById("customersTable"); // Update to the correct ID
    let rows = Array.from(table.getElementsByTagName("tr")).slice(1);
    let switching = true;
  
    while (switching) {
      switching = false;
      for (let i = 0; i < rows.length - 1; i++) {
        let x = rows[i].getElementsByTagName("TD")[row].textContent;
        let y = rows[i + 1].getElementsByTagName("TD")[row].textContent;
  
        let dateX = new Date(x);
        let dateY = new Date(y);
  
        if (dateX > dateY) {
          rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
          switching = true;
          break;
        }
      }
    }
  }
  
  

  const sortRefAscending = () => {
    const table = document.getElementById("customersTable"); // Corrected table ID
    if (!table) return; // Ensure the table exists
    
    const rows = Array.from(table.rows).slice(1); // Get all rows except the header
    
    rows.sort((a, b) => {
      const refA = a.cells[4].textContent.trim(); // Reference No column index 4
      const refB = b.cells[4].textContent.trim();
      
      // Handle alphanumeric or string-based reference numbers
      return refA.localeCompare(refB, undefined, { numeric: true, sensitivity: 'base' });
    });
    
    // Remove existing rows and append the sorted ones back
    const tbody = table.tBodies[0];
    tbody.innerHTML = ""; // Clear current rows
    
    rows.forEach((row) => {
      tbody.appendChild(row); // Add sorted rows back to the table
    });
  };
  
  
  

  const searchTable = () => {
    const searchValue = document.getElementById("search").value.trim().toLowerCase();
    const rows = document.querySelectorAll("#customersTable tbody tr");
  
    rows.forEach((row) => {
      const reasonText = row.cells[2]?.textContent.trim().toLowerCase(); // Ensure it's targeting the 'Reason' column (index 2)
      if (reasonText.includes(searchValue)) {
        row.style.display = ""; // Show row if it matches the search
      } else {
        row.style.display = "none"; // Hide row if it doesn't match
      }
    });
  };
  
  
  
  
  

  const ID = Cookies.get("user_id");
  const [stock, setStock] = useState([]);

  const fetchStockAdjustments = () => {
    axios
      .get(`${config.base_url}/fetch_stock_adjust/${ID}/`)
      .then((res) => {
        console.log("SA RES=", res);
        if (res.data.status) {
          var stck = res.data.stock;
          setStock([]);
          stck.map((i) => {
            setStock((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchStockAdjustments();
  }, []);

  function refreshAll() {
    setStock([]);
    fetchStockAdjustments()
  }
  return (
    <div className="container-scroller">
  <Navbar />
  <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />

    <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
          <div className="container-fluid">
            <div className="card radius-15 h-20 mt-3">
              <div className="card-body">
                <div className="card-title">
                  <div className="row w-100">
                    <div className="col-12 text-center">
                      <h2>ALL STOCK ADJUST</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card radius-15 mt-5">
          <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                {/* Left Side: Search and Sort */}
                <div className="d-flex flex-wrap">
                  <input
                    type="text"
                    id="search"
                    className="form-control w-auto p-3 mb-2"
                    placeholder="Search.."
                    autoComplete="off"
                    onKeyUp={searchTable}
                  />
                  <div className="dropdown ml-2 mb-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary dropdown-toggle text-grey custom-btn"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-sort"></i> Sort by
                    </button>
                    <div
                        className="dropdown-menu"
                        style={{ backgroundColor: "black" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={refreshAll}
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                          }}
                        >
                          All
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={() => sortTableByDate(1)}
                        >
                          Date
                        </a>
                        <a
                          className="dropdown-item"
                          style={{
                            height: "40px",
                            fontSize: "15px",
                            color: "white",
                            cursor: "pointer",
                          }}
                          onClick={sortRefAscending}
                        >
                          Reference No.
                        </a>
                      </div>
                  </div>
                </div>

                {/* Right Side: Export, Filter, and Add Customer */}
                <div className="d-flex flex-wrap">
                  <div className="mb-2 mr-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-grey custom-btn"
                      id="exportBtn"
                      onClick={exportToExcel}
                    >
                      <i className="fa fa-line-chart"></i> Export To Excel
                    </button>
                  </div>

                  <div className="dropdown mb-2 mr-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary dropdown-toggle text-grey custom-btn"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-filter"></i> Filter by
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "all")}
                      >
                        All
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "save")}
                      >
                        Save
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "draft")}
                      >
                        Draft
                      </a>
                    </div>
                  </div>

                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-grey custom-btn"
                      onClick={() => navigate("/new_adjust")}
                    >
                      <i className="fa fa-plus font-weight-light"></i> Stock Adjust
                    </button>
                  </div>
                </div>
              </div>


              
              <div className="custom-table-responsive">
                
                <table id="customersTable" className="table  mt-5">
                  
                  <thead>
                    <tr className="text-center">
                      <th>SL NO</th>
                      <th>DATE</th>
                      <th>REASON</th>
                      <th>DESCRIPTION</th>
                      <th>REFERENCE NO</th>
                      <th>TYPE</th>
                      <th>STATUS</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                {stock &&
                  stock.map((i, index) => (
                    <tr
                      className="clickable-row"
                      onClick={() => navigate(`/stock_adjustment_overview/${i.id}/`)}
                      style={{ cursor: "pointer" }}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td className="text-center">{i.adjusting_date}</td>
                      <td className="text-center">{i.reason}</td>
                      <td className="text-center">
                        <span
                          className="descTooltips"
                          style={{ display: "block" }}
                        >
                          <i
                            className="fa fa-comment"
                            aria-hidden="true"
                            data-toggle="tooltip"
                            data-placement="top"
                            title={i.description ? i.description : "None"}
                          ></i>
                        </span>
                        <span
                          className="descExport"
                          style={{ display: "none" }}
                        >
                          {i.description ? i.description : "None"}
                        </span>
                      </td>
                      <td className="text-center">{i.reference_no}</td>
                      <td className="text-center">{i.mode_of_adjustment}</td>
                      <td className="text-center">{i.status}</td>
                    </tr>
                  ))}
              </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <button style={{ visibility: "hidden" }} id="modalBtn" data-toggle="modal" data-target="#myModal"></button>

          
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default StockAdjustment;
