import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import "./styles/Staff.css"

function Chart_accounts() {
  const [requests, setRequests] = useState([]);
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");
  const [accounts, setAccounts] = useState([]);
  const navigate = useNavigate();
  
  

  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    },
  });
  function searchTable() {
    var rows = document.querySelectorAll("#chartOfAccountsTable tbody tr");
    var val = document
      .getElementById("search")
      .value.trim()
      .replace(/ +/g, " ")
      .toLowerCase();
    rows.forEach(function (row) {
      var text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  }
  function filterAccounts() {
    let input, table, tr, td, i, j, txtValue;
    var selectElement = document.getElementById("categoryId");
    var filter = selectElement.value.toLowerCase();
    var values = [];

    if (filter === "asset") {
      values = [
        "Other Asset",
        "Other Current Asset",
        "Cash",
        "Bank",
        "Fixed Asset",
        "Stock",
        "Payment Clearing",
      ];
    } else if (filter === "liability") {
      values = [
        "Other Current Liability",
        "Credit Card",
        "Long Term Liability",
        "Other Liability",
        "Overseas Tax Payable",
      ];
    } else if (filter === "equity") {
      values = ["Equity"];
    } else if (filter === "income") {
      values = ["Income", "Other Income"];
    } else if (filter === "expense") {
      values = ["Expense", "Cost Of Goods Sold", "Other Expense"];
    }

    table = document.getElementById("chartOfAccountsTable");
    tr = table.getElementsByTagName("tr");

    for (i = 1; i < tr.length; i++) {
      let rowVisible = false;
      td = tr[i].getElementsByTagName("td")[1];

      if (filter === "") {
        rowVisible = true;
      } else {
        // Check if the text content of the cell is in the values array
        if (values.includes(td.innerText)) {
          rowVisible = true;
        }
      }

      // Show/hide the row based on the search results
      tr[i].style.display = rowVisible ? "" : "none";
    }
  }
  function filterTable(row, filterValue) {
    var table = document.getElementById("chartOfAccountsTable");
    var rows = table.getElementsByTagName("tr");

    for (var i = 1; i < rows.length; i++) {
      var statusCell = rows[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none";
      }
    }
  }


  const fetchAccounts = () => {
    axios
      .get(`${config.base_url}/fetch_chart_of_accounts/${ID}/`)
      .then((res) => {
        console.log("CA RES=", res);
        if (res.data.status) {
          var acc = res.data.accounts;
          setAccounts([]);
          acc.map((i) => {
            setAccounts((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  
  useEffect(() => {
    fetchAccounts();
  }, []);

  


  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", width:"100%" }}
        >
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">CHART OF ACCOUNTS</h2>
                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15 mt-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-5 col-12 d-flex justify-content-start align-items-center flex-md-nowrap flex-wrap">
                        <input
                          type="text"
                          id="search"
                          className="form-control"
                          placeholder="Search.."
                          autoComplete="off"
                          style={{ width: "100%", maxWidth: "200px" }}
                          onKeyUp={searchTable}
                        />
                        <select
                          className="form-control btn btn-outline-secondary dropdown-toggle text ml-md-2 mt-2 mt-md-0"
                          id="categoryId"
                          aria-label=".form-select-lg example"
                          name="type"
                          onChange={filterAccounts}
                          style={{ width: "100%", maxWidth: "150px" }}
                        >
                          <option value="" selected>All</option>
                          <option value="Asset">Asset</option>
                          <option value="Liability">Liability</option>
                          <option value="Equity">Equity</option>
                          <option value="Income">Income</option>
                          <option value="Expense">Expense</option>
                        </select>
                      </div>

                      <div className="col-md-7 col-12 d-flex justify-content-end flex-wrap">
                        <div className="dropdown ml-n1 mb-2 mb-md-0">
                          <button
                            type="button"
                            className="btn btn-outline-secondary dropdown-toggle text-black"
                            data-toggle="dropdown"
                            style={{ width: "100%", maxWidth: "200px" }}
                          >
                            <i className="fa fa-filter"></i> filter by
                          </button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" onClick={() => filterTable(3, "all")}>
                              All
                            </a>
                            <a className="dropdown-item" onClick={() => filterTable(3, "active")}>
                              Active
                            </a>
                            <a className="dropdown-item" onClick={() => filterTable(3, "inactive")}>
                              Inactive
                            </a>
                          </div>
                        </div>
                        <Link to="/add_account" className="ml-1">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            style={{ width: "fit-content", height: "fit-content" }}
                          >
                            <i className="fa fa-plus"></i> Account
                          </button>
                        </Link>
                      </div>
                    </div>
                  

                


                {/* <div className="card radius-15 mt-3">
                  <div className="card-body">


                    <div className="row">
                        <div className="col-md-5 d-flex justify-content-start align-items-center">
                            <input
                            type="text"
                            id="search"
                            style={{ width: "45%" }}
                            className="form-control"
                            placeholder="Search.."
                            autoComplete="off"
                            onKeyUp={searchTable}
                            />
                            <select
                                className="form-control btn btn-outline-secondary dropdown-toggle text ml-1"
                                id="categoryId"
                                style={{ width: "33%" }}
                                aria-label=".form-select-lg example"
                                name="type"
                                onChange={filterAccounts}
                                >
                                <option value="" selected>
                                    All
                                </option>
                                <option value="Asset">Asset</option>
                                <option value="Liability">Liability</option>
                                <option value="Equity">Equity</option>
                                <option value="Income">Income</option>
                                <option value="Expense">Expense</option>
                            </select>
                        </div>

                        <div className="col-md-7 col-12 d-flex justify-content-end">
                          <div className="dropdown ml-n1 " >
                            <button
                              type="button"
                              style={{ width: "90%", height: "fit-content" }}
                              className="btn btn-outline-secondary dropdown-toggle text-black "
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-filter"></i> filter by
                            </button>
                            <div
                              className="dropdown-menu"
                              style={{ backgroundColor: "white" }}
                            >
                              <a
                                className="dropdown-item"
                                style={{
                                  height: "40px",
                                  fontSize: "15px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => filterTable(3, "all")}
                              >
                                All
                              </a>
                              <a
                                className="dropdown-item"
                                style={{
                                  height: "40px",
                                  fontSize: "15px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => filterTable(3, "active")}
                              >
                                Active
                              </a>
                              <a
                                className="dropdown-item"
                                style={{
                                  height: "40px",
                                  fontSize: "15px",
                                  color: "black",
                                  cursor: "pointer",
                                }}
                                onClick={() => filterTable(3, "inactive")}
                              >
                                Inactive
                              </a>
                            </div>
                          </div>
                          <Link to="/add_account" className="ml-1">
                            <button
                              type="button"
                              style={{ width: "fit-content", height: "fit-content" }}
                              className="btn btn-outline-secondary text-grey"
                            >
                              <i className="fa fa-plus font-weight-light"></i> Account
                            </button>
                          </Link>
                        </div>
                    </div>
     */}



                    






                    <div className="card-title"></div>

                    <div className="custom-table-responsive">
                      <table className="table mt-4 table-hover" id="chartOfAccountsTable">
                        <thead>
                          <tr>
                            <th
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>ACCOUNT NAME</b>
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>ACCOUNT TYPE</b>
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>ACCOUNT CODE</b>
                            </th>
                            <th
                              style={{
                                textAlign: "center",
                                textTransform: "uppercase",
                              }}
                            >
                              <b>STATUS</b>
                            </th>
                            

                          </tr>
                        </thead>
                        <tbody>
                        {accounts &&
                          accounts.map((a) => (
                            <>
                              {!a.sub_account ? (
                                <tr
                                  className="table-row cursor-pointer"
                                  onClick={()=>navigate(`/view_account/${a.id}/`)}
                                  style={{ cursor: "pointer" }}
                                >
                                  <td>{a.account_name}</td>
                                  <td>{a.account_type}</td>
                                  <td>{a.account_code}</td>
                                  <td className="text-uppercase">{a.status}</td>
                                </tr>
                              ) : null}

                              {accounts.map((sub) => (
                                <>
                                  {sub.sub_account &&
                                  sub.parent_account == a.account_name ? (
                                    <tr
                                      className="table-row cursor-pointer"
                                      onClick={()=>navigate(`/view_account/${sub.id}/`)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      <td>
                                        <i className="fa fa-arrow-right ml-3 mr-2"></i>
                                        {sub.account_name}
                                      </td>
                                      <td>{sub.account_type}</td>
                                      <td>{sub.account_code}</td>
                                      <td className="text-uppercase">{sub.status}</td>
                                    </tr>
                                  ) : null}
                                </>
                              ))}
                            </>
                          ))}
                      </tbody>
                        
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Chart_accounts;
