import { BrowserRouter, Routes, Route } from "react-router-dom";
import AdminPrivateRoutes from "./components/routes/AdminPrivateRoutes";
import Index from "./components/index/Index";
import SignIn from "./components/SignIn/SignIn";
import AdminHome from "./components/admin/AdminHome";
import CompanyReg from "./components/company/CompanyReg";
import PaymentTerms from "./components/admin/PaymentTerms";
import CompanyReg2 from "./components/company/CompanyReg2";
import Modules from "./components/company/Modules";
import StaffReg from "./components/staff/StaffReg";
import StaffReg2 from "./components/staff/StaffReg2";
import DistributorReg from "./components/distributor/DistributorReg";
import DistributorReg2 from "./components/distributor/DistributorReg2";
import AllClients from "./components/admin/AllClients";
import Clients from "./components/admin/Clients";
import ClientsReq from "./components/admin/ClientsReq";
import Distributors from "./components/admin/Distributors";
import AllDistributors from "./components/admin/AllDistributors";
import DistributorsReq from "./components/admin/DistributorsReq";
import DistributorReqOverview from "./components/admin/DistributorReqOverview";
import ClientReqOverview from "./components/admin/ClientReqOverview";
import AllDistributorsOverview from "./components/admin/AllDistributorsOverview";
import AllClientsOverview from "./components/admin/AllClientsOverview";
import DistributorPrivateRoutes from "./components/routes/DistributorPrivateRoutes";
import DistributorHome from "./components/distributor/DistributorHome";
import DAllClients from "./components/distributor/DAllClients";
import DClientReq from "./components/distributor/DClientReq";
import DClientReqOverview from "./components/distributor/DClientReqOverview";
import DClientOverview from "./components/distributor/DClientOverview";
import DistributorProfile from "./components/distributor/DistributorProfile";
import DistributorProfileEdit from "./components/distributor/DistributorProfileEdit";
import CompanyPrivateRoutes from "./components/routes/CompanyPrivateRoutes";
import CompanyStaffPrivateRoutes from "./components/routes/CompanyStaffPrivateRoutes";
import CompanyHome from "./components/company/CompanyHome";
import CompanyProfile from "./components/company/CompanyProfile";
import EditCompanyProfile from "./components/company/EditCompanyProfile";
import EditStaffProfile from "./components/staff/EditStaffProfile";
import StaffReq from "./components/company/StaffReq";
import AllStaffs from "./components/company/AllStaffs";
import EditModules from "./components/company/EditModules";
import Wrong from "./components/company/Wrong";
import AdminNotifications from "./components/admin/AdminNotifications";
import NotificationOverview from "./components/admin/NotificationOverview";
import DistNotifications from "./components/distributor/DistNotifications";
import DistNotificationOverview from "./components/distributor/DistNotificationOverview";
import Items from "./components/company/items/Items";
import AddItem from "./components/company/items/AddItem";
import ViewItem from "./components/company/items/ViewItem";
import ItemHistory from "./components/company/items/ItemHistory";
import EditItem from "./components/company/items/EditItem";
import PriceList from "./components/company/pricelist/PriceList";
import AddPriceList from "./components/company/pricelist/AddPriceList";
import ViewPriceList from "./components/company/pricelist/ViewPriceList";
import PriceListHistory from "./components/company/pricelist/PriceListHistory";
import EditPriceList from "./components/company/pricelist/EditPriceList";
import AddEmployee from"./components/company/employee/addemployee";
import ListoutEmployee from"./components/company/employee/employeelistout";
import EditEmployee from"./components/company/employee/editemployee";
import OverviewEmployee from"./components/company/employee/employeeoveriew";
import EmployeeHistory from"./components/company/employee/employeehistory"; 
import Customer from "./components/company/Customer";
import Add_Customer from "./components/company/Add_Customer";
import Customer_view from "./components/company/Customer_view";
import Customer_Edit from "./components/company/Customer_Edit";
import Customer_History from "./components/company/Customer_History";
import BankingListout from "./components/company/Banking/BankingListout";
import AddBank from "./components/company/Banking/AddBank";
import ViewBank from "./components/company/Banking/ViewBank";
import BankToCash from "./components/company/Banking/BankToCash";
import CashToBank from "./components/company/Banking/CashToBank";
import BankToBank from "./components/company/Banking/BankToBank";
import AdjustBank from "./components/company/Banking/AdjustBank";
import EditTransactions from "./components/company/Banking/EditTransactions";
import EditBank from "./components/company/Banking/EditBank";
import BankTransactionHistory from "./components/company/Banking/BankTransactionHistory";
import StockAdjustment from "./components/company/StockAdjustment";
import New_Adjust from "./components/company/New_Adjust";
import Stock_AdjustOverview from "./components/company/Stock_AdjustOverview";
import EditStockAdjust from "./components/company/EditStockAdjust";
import Stock_AjustHistory from "./components/company/Stock_AjustHistory";
import Recurringinvoice_listoutpage from './components/company/recurringinvoice/Recurringinvoice_listout';
import Add_recurr_invoice from './components/company/recurringinvoice/Add_recurr_invoice';
import View_rec_invoice from './components/company/recurringinvoice/Viewrecurr_invoice';
import Rec_invoice_history from './components/company/recurringinvoice/RecurrInvoice_history';
import EditRec_invoice from './components/company/recurringinvoice/EditRecurr_invoice';
import RetainerInvoiceDetails from './components/company/RetainerInvoiceDetails';
import RetInvoice from './components/company/retainerinvoice/RetInvoice';
import AddRetInvoice from './components/company/retainerinvoice/AddRetInvoice';
import ViewRetInvoice from './components/company/retainerinvoice/ViewRetInvoice';
import RetInvoiceHistory from './components/company/retainerinvoice/RetInvoiceHistory';
import EditRetInvoice from './components/company/retainerinvoice/EditRetInvoice';	
import Invoice from "./components/company/invoice/invoice";
import Addinvoice from "./components/company/invoice/addinvoice";
import ViewInvoice from "./components/company/invoice/invoiceview";
import InvoiceHistory from "./components/company/invoice/invoicehistory";
import EditInvoice from "./components/company/invoice/editinvoice";
import Estimate from "./components/company/estimate/Estimate";
import AddEstimate from "./components/company/estimate/AddEstimate";
import ViewEstimate from "./components/company/estimate/ViewEstimate";
import EditEstimate from "./components/company/estimate/EditEstimate";
import EstimateHistory from "./components/company/estimate/EstimateHistory";
import ManualJournal from './components/company/manualjournal/ManualJournal';
import ManualJournalCreation from './components/company/manualjournal/ManualJournalCreation';
import ViewManualJournal from './components/company/manualjournal/viewjournal';
import ManualJournalHistory from './components/company/manualjournal/ManualJournalHistory';
import EditManualJournal from './components/company/manualjournal/EditManualJournal';
import Vendor from "./components/company/Vendor/Vendor";
import Add_vendor from "./components/company/Vendor/Add_vendor";
import View_vendor from "./components/company/Vendor/View_vendor";
import VendorHistory from "./components/company/Vendor/VendorHistory";
import Edit_vendor from "./components/company/Vendor/Edit_vendor";
import Expense from './components/company/Expense/Expense';
import Add_expense from './components/company/Expense/Add_expense';
import View_expense from './components/company/Expense/View_expense';
import Expense_history from './components/company/Expense/Expense_history';
import Edit_expense from './components/company/Expense/Edit_expense';
import Banklist from "./components/company/bankholder/banklist";
import Addbankholder from "./components/company/bankholder/add_bankholder";
import Viewholder from "./components/company/bankholder/viewholder";
import BankHistory from "./components/company/bankholder/bankhistory";
import Editholder from "./components/company/bankholder/editholder";
import Chart_accounts from './components/company/Chart_accounts';
import AddAccount from './components/company/AddAccount';
import ViewAccount from './components/company/ViewAccount';
import AccountHistory from './components/company/AccountHistory';
import EditAccount from './components/company/EditAccount';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />}></Route>
          <Route path="/login" element={<SignIn />}></Route>
          <Route path="/company_registration" element={<CompanyReg />}></Route>
          <Route path="/company_registration2" element={<CompanyReg2 />}></Route>
          <Route path="/modules_list" element={<Modules />}></Route>
          <Route path="/wrong" element={<Wrong />}></Route>
          {/* <Route path="/term_update_modules" element={<TermUpdateModules />}></Route> */}

          <Route path="/distributor_registration" element={<DistributorReg />}></Route>
          <Route path="/distributor_registration2" element={<DistributorReg2 />}></Route>
          
          <Route path="/staff_registration" element={<StaffReg />}></Route>
          <Route path="/staff_registration2" element={<StaffReg2 />}></Route>

          <Route element={<AdminPrivateRoutes />}>
            <Route path="/admin_home" element={<AdminHome />}></Route>
            <Route path="/payment_terms/new_term" element={<PaymentTerms />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/all_clients" element={<AllClients />}></Route>
            <Route path="/clients_requests" element={<ClientsReq />}></Route>
            <Route path="/client_request_overview/:id/" element={<ClientReqOverview />}></Route>
            <Route path="/all_clients_overview/:id/" element={<AllClientsOverview />}></Route>
            <Route path="/distributors" element={<Distributors />}></Route>
            <Route path="/all_distributors" element={<AllDistributors />}></Route>
            <Route path="/distributors_requests" element={<DistributorsReq />}></Route>
            <Route path="/distributors_request_overview/:id/" element={<DistributorReqOverview />}></Route>
            <Route path="/all_distributors_overview/:id/" element={<AllDistributorsOverview />}></Route>
            <Route path="/admin_notifications" element={<AdminNotifications />}></Route>
            <Route path="/anotification_overview/:id/" element={<NotificationOverview />}></Route>
          </Route>
          <Route element={<DistributorPrivateRoutes />}>
            <Route path="/distributor_home" element={<DistributorHome />}></Route>
            <Route path="/distributor_notifications" element={<DistNotifications />}></Route>
            <Route path="/dnotification_overview/:id/" element={<DistNotificationOverview />}></Route>
            <Route path="/distributor_profile" element={<DistributorProfile />}></Route>
            <Route path="/edit_distributor_profile" element={<DistributorProfileEdit />}></Route>
            <Route path="/DClient_req" element={<DClientReq />}></Route>
            <Route path="/DClients" element={<DAllClients />}></Route>
            <Route path="/DClient_request_overview/:id/" element={<DClientReqOverview />}></Route>
            <Route path="/DClient_overview/:id/" element={<DClientOverview />}></Route>
          </Route>
          <Route element={<CompanyPrivateRoutes />}>
            <Route path="/company_staffs/staff_requests" element={<StaffReq />}></Route>
            <Route path="/company_staffs/all_staffs" element={<AllStaffs />}></Route>
            <Route path="/edit_company_profile" element={<EditCompanyProfile />}></Route>
            <Route path="/edit_modules" element={<EditModules />}></Route>
          </Route>
          <Route element={<CompanyStaffPrivateRoutes />}>
            <Route path="/company_home" element={<CompanyHome />}></Route>
            <Route path="/company_profile" element={<CompanyProfile />}></Route>
            <Route path="/edit_staff_profile" element={<EditStaffProfile />}></Route>
          </Route>
          {/* Items */}
          <Route path="/Items" element={<Items />}></Route>
          <Route path="/AddItem" element={<AddItem />}></Route>
          <Route path="/ViewItem/:itemId/" element={<ViewItem />}></Route>
          <Route path="/ItemHistory/:itemId/" element={<ItemHistory />}></Route>
          <Route path="/EditItem/:itemId/" element={<EditItem />}></Route>

          <Route path="/PriceList" element={<PriceList />}></Route>
          <Route path="/AddPriceList" element={<AddPriceList />}></Route>
          <Route path="/ViewPriceList/:priceListId/" element={<ViewPriceList />}></Route>
          <Route path="/PriceListHistory/:priceListId/" element={<PriceListHistory />}></Route>
          <Route path="/EditPriceList/:priceListId/" element={<EditPriceList />}></Route>

          {/* employee */}
          <Route path="/employeeadd" element={<AddEmployee />}></Route>
          <Route path="/listoutemployee" element={<ListoutEmployee />}></Route>
          <Route path="/overviewemployee/:itemId/" element={<OverviewEmployee />}></Route>
          <Route path="/employee_history/:itemId/" element={<EmployeeHistory />}></Route>
          <Route path="/edit_employee/:itemId/" element={<EditEmployee />}></Route>

          <Route path="/customers" element={<Customer />}></Route>
          <Route path="/add_customer" element={<Add_Customer />}></Route>
          <Route path="/customer_view/:customerId/" element={<Customer_view />}></Route>
          <Route path="/customer_edit/:customerId/" element={<Customer_Edit/>}></Route>
          <Route path="/customer_history/:customerId/" element={<Customer_History/>}></Route>

          <Route path="/banking/bankinglistout" element={<BankingListout />}></Route>
          <Route path="/banking/add_bank" element={<AddBank />}></Route>
          <Route path="/banking/view_bank/:bankId/" element={<ViewBank />}></Route>
          <Route path="/banking/bank_to_cash/:bankId/" element={<BankToCash />}></Route>
          <Route path="/banking/cash_to_bank/:bankId/" element={<CashToBank />}></Route>
          <Route path="/banking/bank_to_bank/:bankId/" element={<BankToBank />}></Route>
          <Route path="/banking/bank_adjust/:bankId/" element={<AdjustBank />}></Route>
          <Route path="/edit_transaction/:transId/" element={<EditTransactions />}></Route>
          <Route path="/banking/edit_bank/:bankId/" element={<EditBank />}></Route>
          <Route path="/bank_transaction_history/:transactionId/" element={<BankTransactionHistory />}></Route>

          <Route path="/stock_adjustment" element={<StockAdjustment />}></Route>
          <Route path="/new_adjust" element={<New_Adjust />}></Route>
          <Route path="/stock_adjustment_overview/:stockId/" element={<Stock_AdjustOverview/>}></Route>
          <Route path="/edit_stock_adjust/:stockId/" element={<EditStockAdjust/>}></Route>
          <Route path="/stock_adjust_history/:stockId/" element={<Stock_AjustHistory/>}></Route>

          <Route path="/recurringinvoice_listoutpage" element={<Recurringinvoice_listoutpage />}></Route>
          <Route path="/add_recurr_invoice" element={<Add_recurr_invoice/>}></Route>
          <Route path="/view_rec_invoice/:invoiceId/" element={<View_rec_invoice/>}></Route>
          <Route path="/rec_invoice_history/:invoiceId/" element={<Rec_invoice_history/>}></Route>
          <Route path="/edit_rec_invoice/:invoiceId/" element={<EditRec_invoice/>}></Route>

          {/* Retainer Invoice  Details */}
          <Route path="/retainer_invoice_details" element={<RetainerInvoiceDetails />}></Route>

          {/* Retainer Invoice */}
          <Route path="/ret_invoice" element={<RetInvoice />}></Route>
          <Route path="/add_ret_invoice" element={<AddRetInvoice />}></Route>
          <Route path="/view_ret_invoice/:invoiceId/" element={<ViewRetInvoice />}></Route>
          <Route path="/ret_invoice_history/:invoiceId/" element={<RetInvoiceHistory />}></Route>
          <Route path="/edit_ret_invoice/:invoiceId/" element={<EditRetInvoice />}></Route>

          <Route path="/invoice" element={<Invoice />}></Route>
          <Route path="/addinvoice" element={<Addinvoice />}></Route>
          <Route path="/invoiceview/:invoiceId/" element={<ViewInvoice />}></Route>
          <Route path="/invoicehistory/:invoiceId/" element={<InvoiceHistory />}></Route>
          <Route path="/editinvoice/:invoiceId/" element={<EditInvoice />}></Route>

          <Route path="/Estimate" element={<Estimate />}></Route>
          <Route path="/AddEstimate" element={<AddEstimate />}></Route>
          <Route path="/ViewEstimate/:estimateId/" element={<ViewEstimate />}></Route>
          <Route path="/EditEstimate/:estimateId/" element={<EditEstimate />}></Route>
          <Route path="/EstimateHistory/:estimateId/" element={<EstimateHistory />}></Route>

          {/* ManualJournal */}
          <Route path="/manualjournal" element={<ManualJournal />} />
          <Route path="/manualjournalcreation" element={<ManualJournalCreation />} />
          <Route path="/view_manual_journal/:journalId/" element={<ViewManualJournal />} />
          <Route path="/edit_manual_journal/:journalId/" element={<EditManualJournal />} />
          <Route path="/manual_journal_history/:journalId/" element={<ManualJournalHistory />} />

          <Route path="/vendor" element={<Vendor />}></Route>
          <Route path="/add_vendor" element={<Add_vendor />}></Route>
          <Route path="/view_vendors/:vendorId/" element={<View_vendor />}></Route>
          <Route path="/vendor_history/:vendorId/" element={<VendorHistory />}></Route>
          <Route path="/edit_vendor/:vendorId/" element={<Edit_vendor />}></Route>

          <Route path="/expense" element={<Expense />}></Route>
          <Route path="/add_expense" element={<Add_expense />}></Route>
          <Route path="/view_expense/:expenseId/" element={<View_expense/>}></Route>
          <Route path="/expense_history/:expenseId/" element={<Expense_history/>}></Route>
          <Route path="/edit_expense/:expenseId/" element={<Edit_expense/>}></Route>

          <Route path="/banklist" element={<Banklist />}></Route>
          <Route path="/add_bankholder" element={<Addbankholder />}></Route>
          <Route path="/viewholder/:holderId/" element={<Viewholder />}></Route>
          <Route path="/bankhistory/:holderId/" element={<BankHistory />}></Route>
          <Route path="/editholder/:holderId/" element={<Editholder />}></Route>

          <Route path="/chart_accounts" element={<Chart_accounts />}></Route>
          <Route path="/add_account" element={<AddAccount />}></Route>
          <Route path="/view_account/:accountId/" element={<ViewAccount />}></Route>
          <Route path="/edit_account/:accountId/" element={<EditAccount />}></Route>      
          <Route path="/account_history/:accountId/" element={<AccountHistory />}></Route>

        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
