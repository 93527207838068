import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import { Link, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";

function RetInvoiceHistory() {
  const ID = Cookies.get("user_id");
  const { invoiceId } = useParams();
  const [history, setHistory] = useState([]);
  const [invoice, setInvoice] = useState({});

  const fetchRetInvoiceHistory = () => {
    axios
      .get(`${config.base_url}/fetch_ret_invoice_history/${invoiceId}/`)
      .then((res) => {
        console.log("INV HIST=", res);
        if (res.data.status) {
          var sls = res.data.invoice;
          var hist = res.data.history;
          setInvoice(sls);
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchRetInvoiceHistory();
  }, []);

  return (
    
    <div className="container-scroller">
    <Navbar />
    <div className="container-fluid page-body-wrapper d-flex">
      <Sidebar />
      <div
        className="main-panel"
        style={{ background: "#a9a9a961", maxWidth: "100vw" }}
      >
           <div className="content-wrapper">
          <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
            <div className="container-fluid">
              <div className="card radius-15 mt-3">
                <div className="card-body" style={{ width: "100%" }}>
                  <div className="card-title">
                   
                        <center>
              <h3
                className="card-title"
                style={{ textTransform: "Uppercase" }}
              >
                RETAINER INVOICE TRANSACTIONS
              </h3>
              {invoice.status == "Draft" ? (
                <h6
                  className="blinking-text"
                  style={{ color: "red", width: "140px", fontWeight: "bold" }}
                >
                  Draft
                </h6>
              ) : (
                <h6
                  style={{
                    width: "140px",
                    color: "green",
                    fontWeight: "bold",
                  }}
                >
                  Saved
                </h6>
              )}
            </center>
          </div>
        </div>
      </div>


        <div
          className="card card-registration card-registration-2"
          style={{ borderRadius: "15px" }}
        >
          <div className="card-body p-0">
            <div id="history">
              <center>
                <h3 className="mt-3 text-uppercase">
                  #{invoice.ret_invoice_no} - TRANSACTIONS
                </h3>
              </center>
              <div className="table-responsive px-2">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="text-center">SL NO.</th>
                      <th className="text-center">DATE</th>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">DONE BY</th>
                    </tr>
                  </thead>
                  <tbody>
                    {history &&
                      history.map((h, index) => (
                        <tr>
                          <td style={{ textAlign: "center" }}>{index + 1}</td>
                          <td style={{ textAlign: "center" }}>{h.date}</td>
                          {h.action == "Created" ? (
                            <td className="text-success text-center">
                              {h.action}
                            </td>
                          ) : (
                            <td className="text-warning text-center">
                              {h.action}
                            </td>
                          )}
                          <td style={{ textAlign: "center" }}>{h.name}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  

  );
}

export default RetInvoiceHistory;
