import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../functions/config";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";
import * as XLSX from 'xlsx'; 
import "./styles/customer.css";
import "bootstrap/dist/css/bootstrap.css";



function Customer() {
  const navigate = useNavigate();
  function exportToExcel() {
    const Table = document.getElementById("customersTable");
    
    if (!Table) {
      console.error("Table element not found");
      return;
    }
  
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Customers.xlsx");
  }

  function sortTable(columnIndex) {
    var table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("customersTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();
        y = rows[i + 1]
          .getElementsByTagName("td")
          [columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  }

  function filterTable(row, filterValue) {
    var table = document.getElementById("customersTable");
    var rows = table.getElementsByTagName("tr");

    for (var i = 1; i < rows.length; i++) {
      var statusCell = rows[i].getElementsByTagName("td")[row];

      if (
        filterValue == "all" ||
        statusCell.textContent.toLowerCase() == filterValue
      ) {
        rows[i].style.display = "";
      } else {
        rows[i].style.display = "none";
      }
    }
  }

  function sortBalAsc() {
    var table = document.getElementById("customersTable");
    var rows = Array.from(table.rows).slice(1);

    rows.sort(function (a, b) {
      var balA = parseFloat(a.cells[7].textContent);
      var balB = parseFloat(b.cells[7].textContent);
      return balA - balB;
    });

    // Remove existing rows from the table
    for (var i = table.rows.length - 1; i > 0; i--) {
        table.deleteRow(i);
    }

    // Append the sorted rows back to the table
    rows.forEach(function (row) {
        table.tBodies[0].appendChild(row);
    });
  }

  function searchTable(){
    var rows = document.querySelectorAll('#customersTable tbody tr');
    var val = document.getElementById('search').value.trim().replace(/ +/g, ' ').toLowerCase();
    rows.forEach(function(row) {
      var text = row.textContent.replace(/\s+/g, ' ').toLowerCase();
      row.style.display = text.includes(val) ? '' : 'none';
    });
  }
  
  const [companyName, setCompanyName] = useState("COMPANY NAME");
  const [paymentDetails, setPaymentDetails] = useState({
    PaymentRequest: false,
    daysLeft: 0,
    paymentTerm: false,
    endDate: "",
    alertMessage: false,
  });
  const [customers, setCustomers] = useState([]);
  const ID = Cookies.get("user_id");
  const user = Cookies.get("role");

  const fetchCustomers = () => {
    axios.get(`${config.base_url}/get_customer/${ID}/`)
      .then((res) => {
        console.log("CUST RES=", res);
        if (res.data.status) {
          console.log("API Response Data:", res.data.customers);  // Debugging line
          const cust = res.data.customers;
          setCustomers(cust.map((i) => ({
            id: i.id || 'NULL',
            name: `${i.first_name || 'NULL'} ${i.last_name || 'NULL'}`,
            gstType: i.gst_type || 'NULL',
            gstIn: i.gstin || 'NULL',
            mailId: i.email || 'NULL',
            openingBalance: i.opening_balance || 'NULL',
            balance: i.current_balance || 'NULL',
            status: i.status || 'NULL'
          })));
        }
      })
      .catch((err) => {
        console.error("API Error:", err);
      });
  };
  
  
  useEffect(()=>{
    fetchCustomers();
  },[])
  

  const fetchCompanyDetails = () => {
    axios.get(`${config.base_url}/check_payment_term/${ID}/`)
      .then((res) => {
        if (res.data.status) {
          setCompanyName(res.data.companyName);
          if (user === "Company") {
            const pData = {
              PaymentRequest: res.data.payment_request,
              daysLeft: res.data.days_left,
              paymentTerm: res.data.paymentTerm,
              endDate: res.data.endDate,
              alertMessage: res.data.alert_message,
            };
            setPaymentDetails(pData);
            if (res.data.alert_message) {
              document.getElementById("modalBtn").click();
            }
          }
        }
      })
      .catch((err) => {
        console.log("HOME ERROR==", err);
      });
  };

  useEffect(() => {
    fetchCustomers();
    fetchCompanyDetails();
  }, []);
  function refreshAll(){
    setCustomers([])
    fetchCustomers();
  }

  return (
    <div className="container-scroller">
  <Navbar />
  <div className="container-fluid page-body-wrapper d-flex">
    <Sidebar />

    <div className="main-panel px-3 py-2" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
      <div className="content-wrapper">
        <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
          <div className="container-fluid">
            <div className="card radius-15 h-20 mt-3">
              <div className="card-body">
                <div className="card-title">
                  <div className="row w-100">
                    <div className="col-12 text-center">
                      <h2>CUSTOMERS</h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card radius-15 mt-5">
          <div className="card-body">
              <div className="d-flex justify-content-between align-items-center mb-3 flex-wrap">
                {/* Left Side: Search and Sort */}
                <div className="d-flex flex-wrap">
                  <input
                    type="text"
                    id="search"
                    className="form-control w-auto p-3 mb-2"
                    placeholder="Search.."
                    autoComplete="off"
                    onKeyUp={searchTable}
                  />
                  <div className="dropdown ml-2 mb-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary dropdown-toggle text-grey custom-btn"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-sort"></i> Sort by
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        onClick={refreshAll}
                        style={{ height: "40px", fontSize: "15px", color: "white" }}
                      >
                        All
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => sortTable(1)}
                      >
                        Name
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={sortBalAsc}
                      >
                        Balance
                      </a>
                    </div>
                  </div>
                </div>

                {/* Right Side: Export, Filter, and Add Customer */}
                <div className="d-flex flex-wrap">
                  <div className="mb-2 mr-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-grey custom-btn"
                      id="exportBtn"
                      onClick={exportToExcel}
                    >
                      <i className="fa fa-line-chart"></i> Export To Excel
                    </button>
                  </div>

                  <div className="dropdown mb-2 mr-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary dropdown-toggle text-grey custom-btn"
                      data-toggle="dropdown"
                    >
                      <i className="fa fa-filter"></i> Filter by
                    </button>
                    <div
                      className="dropdown-menu"
                      style={{ backgroundColor: "black" }}
                    >
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "all")}
                      >
                        All
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "active")}
                      >
                        Active
                      </a>
                      <a
                        className="dropdown-item"
                        style={{
                          height: "40px",
                          fontSize: "15px",
                          color: "white",
                          cursor: "pointer",
                        }}
                        onClick={() => filterTable(6, "inactive")}
                      >
                        Inactive
                      </a>
                    </div>
                  </div>

                  <div className="mb-2">
                    <button
                      type="button"
                      className="btn btn-outline-secondary text-grey custom-btn"
                      onClick={() => navigate("/add_customer")}
                    >
                      <i className="fa fa-plus font-weight-light"></i> Customer
                    </button>
                  </div>
                </div>
              </div>


              
              <div className="custom-table-responsive">
                
                <table id="customersTable" className="table  mt-5">
                  
                  <thead>
                    <tr className="text-center">
                      <th>#</th>
                      <th>NAME</th>
                      <th>GST TYPE</th>
                      <th>GST IN</th>
                      <th>MAIL ID</th>
                      <th>OPENING BALANCE</th>
                      <th>STATUS</th>
                      <th>BALANCE</th>
                    </tr>
                  </thead>
                  <tbody>
                        {customers.length > 0 ? (
                          customers.map((item, index) => (
                            <tr
                              key={index}
                              onClick={() => navigate(`/customer_view/${item.id}`)}
                              className="customer-row"
                              style={{ cursor: 'pointer' }}
                            >
                              <td className="text-center">{index + 1}</td>
                              <td className="text-center">{item.name}</td>
                              <td className="text-center">{item.gstType}</td>
                              <td className="text-center">{item.gstIn}</td>
                              <td className="text-center">{item.mailId}</td>
                              <td className="text-center">{item.openingBalance}</td>
                              <td className="text-center">{item.status}</td>
                              <td className="text-center">{item.balance}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="8">No data found</td>
                          </tr>
                        )}
                      </tbody>
                </table>
              </div>
            </div>
          </div>
          
          <button style={{ visibility: "hidden" }} id="modalBtn" data-toggle="modal" data-target="#myModal"></button>

          {!paymentDetails.PaymentRequest && paymentDetails.alertMessage && (
            <div className="modal fade" id="myModal" tabIndex="-1" role="dialog">
              <div className="modal-dialog" role="document">
                <div className="modal-content" style={{ backgroundColor: "rgb(209 209 209)", border: "1px solid rgba(255, 255, 255, 0.3)" }}>
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLongTitle">
                      <i className="fa fa-exclamation-triangle fa-lg text-danger ml-1"></i>
                      <span className="font-monospace">
                        {paymentDetails.paymentTerm ? "Payment Term Ends in" : "Trial Period Ends in"}
                        {paymentDetails.daysLeft !== 0 ? <span className="text-danger"> {paymentDetails.daysLeft} days</span> : <span className="text-danger">Today</span>}
                      </span>
                    </h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  {paymentDetails.paymentTerm ? (
                    <div className="modal-body">
                      <h6 className="text-secondary mt-1">
                        Your current plan is expiring on {paymentDetails.endDate}
                      </h6>
                      <div className="row mb-3 mt-3">
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button className="btn btn-sm btn-success ms-5" >
                            Click here to view plans
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-body">
                      <h6 className="text-secondary mt-1">{paymentDetails.alertMessage}</h6>
                      <div className="row mb-3 mt-3">
                        <div className="col-md-4"></div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <button className="btn btn-sm btn-success ms-5" >
                            Click here to view plans
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  </div>
</div>

  );
}

export default Customer;
