import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import Cookies from "js-cookie";
import * as XLSX from "xlsx";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css";
import config from "../../../functions/config";

function BankingListout() {
  
  const navigate = useNavigate();
  

  const exportToExcel = () => {
    const Table = document.getElementById("bankTable");
    const ws = XLSX.utils.table_to_sheet(Table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "Bank.xlsx");
  };

  const sortTable = (columnIndex) => {
    let table, rows, switching, i, x, y, shouldSwitch;
    table = document.getElementById("bankTable");
    switching = true;

    while (switching) {
      switching = false;
      rows = table.rows;

      for (i = 1; i < rows.length - 1; i++) {
        shouldSwitch = false;
        x = rows[i].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();
        y = rows[i + 1].getElementsByTagName("td")[columnIndex].textContent.toLowerCase();

        if (x > y) {
          shouldSwitch = true;
          break;
        }
      }

      if (shouldSwitch) {
        rows[i].parentNode.insertBefore(rows[i + 1], rows[i]);
        switching = true;
      }
    }
  };

  const filterTable = (row, filterValue) => {
    const table = document.getElementById("bankTable");
    const rows = table.getElementsByTagName("tr");

    for (let i = 1; i < rows.length; i++) {
      const statusCell = rows[i].getElementsByTagName("td")[row];
      rows[i].style.display = filterValue === "all" || statusCell.textContent.toLowerCase() === filterValue ? "" : "none";
    }
  };

  const sortBalAscending = () => {
    const table = document.getElementById("bankTable");
    const rows = Array.from(table.rows).slice(1);

    rows.sort((a, b) => {
      const hsnA = parseInt(a.cells[6].textContent);
      const hsnB = parseInt(b.cells[6].textContent);
      return hsnA - hsnB;
    });

    // Remove existing rows from the table
    for (let i = table.rows.length - 1; i > 0; i--) {
      table.deleteRow(i);
    }

    // Append the sorted rows back to the table
    rows.forEach(row => table.tBodies[0].appendChild(row));
  };

  const searchTable = () => {
    const rows = document.querySelectorAll("#bankTable tbody tr");
    const val = document.getElementById("search").value.trim().replace(/ +/g, " ").toLowerCase();
    rows.forEach(row => {
      const text = row.textContent.replace(/\s+/g, " ").toLowerCase();
      row.style.display = text.includes(val) ? "" : "none";
    });
  };

 
    

 


  const ID = Cookies.get("user_id");
  console.log("Id" , ID )
  const [banks, setBanks] = useState([]);

  const fetchBanks = () => {
    axios
      .get(`${config.base_url}/fetch_banks/${ID}/`)
      .then((res) => {
        console.log("BNK RES=", res);
        if (res.data.status) {
          var bnks = res.data.banks;
          setBanks([]);
          bnks.map((i) => {
            setBanks((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERR", err);
      });
  };

  useEffect(() => {
    fetchBanks();
  }, []);

  function refreshAll() {
    setBanks([]);
    fetchBanks();
  }

  function formatDate(value) {
    if (value != "") {
      var date = new Date(value);
      return date.toISOString().slice(0, 10);
    } else {
      return "";
    }
  }

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">BANKING</h2>
                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15" style={{marginTop:"60px"}}>
                  <div className="card-body">
                    <div className="card-title">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="d-flex align-items-center">
                            <input
                              type="text"
                              id="search"
                              className="form-control"
                              placeholder="Search.."
                              autoComplete="off"
                              onKeyUp={searchTable}
                            />
                            <div className="dropdown ml-1" style={{ justifyContent: "left" }}>
                              <button
                                type="button"
                                style={{ width: "fit-content", height: "fit-content" }}
                                className="btn btn-outline-secondary dropdown-toggle text-grey"
                                data-toggle="dropdown"
                              >
                                <i className="fa fa-sort"></i> Sort by
                              </button>
                              <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                                <a
                                  className="dropdown-item"
                                 
                                  style={{ height: "40px", fontSize: "15px", color: "white" }}
                                >
                                  All
                                </a>
                                <a
                                  className="dropdown-item"
                                  style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                  onClick={() => sortTable(1)}
                                >
                                  Name
                                </a>
                                <a
                                  className="dropdown-item"
                                  style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                  onClick={sortBalAscending}
                                >
                                  Balance
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-6 d-flex justify-content-end">
                          <button
                            type="button"
                            style={{ width: "fit-content", height: "fit-content" }}
                            className="btn btn-outline-secondary text-grey"
                            id="exportBtn"
                            onClick={exportToExcel}
                          >
                            <i className="fa fa-table"></i> Export To Excel
                          </button>
                          <div className="dropdown ml-1">
                            <button
                              type="button"
                              style={{ width: "fit-content", height: "fit-content" }}
                              className="btn btn-outline-secondary dropdown-toggle text-grey"
                              data-toggle="dropdown"
                            >
                              <i className="fa fa-filter"></i> filter by
                            </button>
                            <div className="dropdown-menu" style={{ backgroundColor: "black" }}>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(7, "all")}
                              >
                                All
                              </a>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(7, "active")}
                              >
                                Active
                              </a>
                              <a
                                className="dropdown-item"
                                style={{ height: "40px", fontSize: "15px", color: "white", cursor: "pointer" }}
                                onClick={() => filterTable(7, "inactive")}
                              >
                                Inactive
                              </a>
                            </div>
                          </div>
                          <Link to="/banking/add_bank" className="ml-1">
                            <button
                              type="button"
                              style={{ width: "fit-content", height: "fit-content" }}
                              className="btn btn-outline-secondary text-grey"
                            >
                              <i className="fa fa-plus font-weight-light"></i> Bank
                            </button>
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="custom-table-responsive">
                      <table className="table mt-4 table-hover" id="bankTable">
                        <thead>
                          <tr>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>SL NO.</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>DATE</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>BANK NAME</b></th>
                            
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>ACCOUNT NUMBER</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>IFSC CODE</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>OPENING BALANCE</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>BALANCE</b></th>
                            <th style={{ textAlign: "center", textTransform: "uppercase" }}><b>STATUS</b></th>
                          </tr>
                        </thead>
                        <tbody>
                        {banks &&
                  banks.map((i, index) => (
                            <tr  onClick={() => navigate(`/banking/view_bank/${i.id}/`)}>
                              <td style={{ textAlign: "center" }}>{index + 1}</td>
                              <td style={{ textAlign: "center" }}>{formatDate(i.date)}</td>
                              <td style={{ textAlign: "center" }}>{i.bank_name}</td>
                              <td style={{ textAlign: "center" }}>{i.account_number}</td>
                              <td style={{ textAlign: "center" }}>{i.ifsc_code}</td>
                              <td style={{ textAlign: "center" }}>{i.opening_balance}</td>
                              <td style={{ textAlign: "center" }}>{i.current_balance}</td>
                              <td style={{ textAlign: "center" }}>{i.bank_status}</td>
                             
                               
                               
                                
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankingListout;
