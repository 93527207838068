
import React, { useEffect, useState } from "react";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "../styles/Staff.css";
import Cookies from "js-cookie";
import { Link, useNavigate } from "react-router-dom";import axios from "axios";
import config from "../../../functions/config";
import Swal from "sweetalert2";



function AddBank() {

  const ID = Cookies.get("user_id");
  console.log("Id", ID);
    const navigate = useNavigate();
  
    var currentDate = new Date();
    var formattedDate = currentDate.toISOString().slice(0, 10);
  
    const [bankName, setBankName] = useState("");
    const [ifscCode, setIfscCode] = useState("");
    const [accountNumber, setAccountNumber] = useState("");
    const [branchName, setBranchName] = useState("");
    const [openingBalance, setOpeningBalance] = useState(0);
    const [balanceType, setBalanceType] = useState("DEBIT");
    const [date, setDate] = useState(formattedDate);
  
    const handleSubmit = (e) => {
      e.preventDefault();
  
      var dt = {
        Id: ID,
        bank_name: bankName,
        ifsc_code: ifscCode,
        branch_name: branchName,
        opening_balance: openingBalance,
        opening_balance_type: balanceType,
        date: date,
        current_balance: openingBalance,
        account_number: accountNumber,
        bank_status: "Active",
      };
  
      axios
        .post(`${config.base_url}/create_new_bank/`, dt)
        .then((res) => {
          console.log("BNK RES=", res);
          if (res.data.status) {
            Toast.fire({
              icon: "success",
              title: "Bank Created",
            });
            navigate("/banking/bankinglistout");
          }
          if (!res.data.status && res.data.message != "") {
            Swal.fire({
              icon: "error",
              title: `${res.data.message}`,
            });
          }
        })
        .catch((err) => {
          console.log("ERROR=", err);
          if (!err.response.data.status) {
            Swal.fire({
              icon: "error",
              title: `${err.response.data.message}`,
            });
          }
        });
    };
  
  function handleBankAccountNoChange(val) {
      setAccountNumber(val);
      checkBankAccountNumber(val);
    
  }
  function checkBankAccountNumber(val) {
    const ba_num = val.trim(); // Trim whitespace from the input
    if (ba_num.length  >=  14 && /^[0-9]+$/.test(ba_num)) { // Check if the input is exactly 14 digits
        const params = {
            Id: ID, // Ensure ID is defined and valid
            number: ba_num, // Correctly named to match backend
            bank: 'YourBankName' // Ensure you include the bank name if required
        };

        axios
            .get(`${config.base_url}/check_bank_account_number/`, { params })
            .then((res) => {
                console.log("BA NUM Res=", res);
                const errorElement = document.getElementById("BANoErr");
                if (errorElement) {
                    if (res.data.status) {
                        errorElement.innerText = ""; // Clear the error message if no errors
                    } else {
                        errorElement.innerText = res.data.message; // Display the error message
                    }
                } else {
                    console.error("Element with ID 'BANoErr' not found.");
                }
            })
            .catch((err) => {
                console.log("ERROR=", err);
                const errorElement = document.getElementById("BANoErr");
                if (errorElement) {
                    errorElement.innerText = "An error occurred. Please try again later."; // More descriptive error message
                } else {
                    console.error("Element with ID 'BANoErr' not found.");
                }
            });
    } else {
        const errorElement = document.getElementById("BANoErr");
        if (errorElement) {
            errorElement.innerText = "Account number must be exactly 14 digits or more."; // Show error for invalid input
        } else {
            console.error("Element with ID 'BANoErr' not found.");
        }
    }
}


  
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
  


  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div className="main-panel" style={{ background: "#a9a9a961", maxWidth: "100vw" }}>
          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                      <center>
                        <h2 className="mt-3">ADD BANK</h2>
                      </center>
                      <hr />
                    </div>
                  </div>
                </div>
                <div className="card radius-15">
                  <div className="card-body">
                    <div className="card-title"></div>
                    <div className="container-fluid">
              <form id="bankForm" className="px-1" onSubmit={handleSubmit}>
                <div className="row w-100">
                  <div className="col-md-5 p-2">
                    <label for="">BANK NAME</label>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5 p-2">
                    <label for="">ACCOUNT NUMBER</label>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-md-5 p-2">
                    <input
                      className="form-control"
                      type="text"
                      name="bname"
                      value={bankName}
                      onChange={(e) => setBankName(e.target.value)}
                      id="bname"
                      required
                    />
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5 p-2">
                  <span id="BANoErr" style={{color:"red"}}></span>

                    <input
                      className="form-control"
                      type="number"
                      name="acc_num"
                      id="acc_num"
                      value={accountNumber}
                      onChange={(e) =>
                        handleBankAccountNoChange(
                            e.target.value
                        )
                    }
                      required
                    />
                    <span
                      id="accountNumberError"
                      className="text-danger"
                    ></span>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-md-5 p-2">
                    <label for="">BANK IFSC CODE</label>
                  </div>
                  <div className="col-md-2"></div>
                  <div className="col-md-5 p-2">
                    <label for="">BRANCH NAME</label>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-md-5 p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={ifscCode}
                      onChange={(e) => setIfscCode(e.target.value)}
                      name="ifsc"
                      required
                    />
                  </div>
                  <div className="col-md-2"></div>

                  <div className="col-md-5 p-2">
                    <input
                      className="form-control"
                      type="text"
                      value={branchName}
                      onChange={(e) => setBranchName(e.target.value)}
                      name="branch"
                      required
                    />
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-md-5 p-2">
                    <label for="">OPENING BALANCE</label>
                  </div>
                  <div className="col-md-2"></div>

                  <div className="col-md-5 p-2">
                    <label for="">DATE</label>
                  </div>
                </div>

                <div className="row w-100">
                  <div className="col-md-5 form-inline p-2">
                    <input
                      className="form-control"
                      type="number"
                      value={openingBalance}
                      onChange={(e) => setOpeningBalance(e.target.value)}
                      name="Opening"
                      required
                    />
                    <select
                      className="form-control ml-1"
                      value={balanceType}
                      onChange={(e) => setBalanceType(e.target.value)}
                      name="op_type"
                      id=""
                    >
                      <option value="DEBIT">DEBIT</option>
                      <option value="CREDIT">CREDIT</option>
                    </select>
                  </div>
                  <div className="col-md-2"></div>

                  <div className="col-md-5 p-2">
                    <input
                      className="form-control"
                      type="date"
                      name="date"
                      value={date}
                      onChange={(e) => setDate(e.target.value)}
                      id="dateInput"
                    />
                  </div>
                </div>

                <div className="row mt-5 mb-5 w-100">
                  <div className="col-md-4"></div>
                  <div className="col-md-4 d-flex justify-content-center">
                    <button
                      className="btn btn-outline-secondary text-dark"
                      type="submit"
                      style={{ width: "50%", height: "fit-content" }}
                    >
                      SAVE
                    </button>
                    <Link
                      to="/banking/bankinglistout"
                      className="btn btn-outline-secondary ml-1 text-dark"
                      style={{ width: "fit-content", height: "fit-content" }}
                    >
                      CANCEL
                    </Link>
                  </div>
                  <div className="col-md-4"></div>
                </div>
              </form>
            </div>

                    </div>
                    </div>

               
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}

export default AddBank;
