import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import axios from "axios";
import config from "../../../functions/config";
import { Link, useNavigate,useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../Navbar";
import Sidebar from "../Sidebar";
import "./styles/Staff.css"
import * as XLSX from "xlsx";

function PriceListHistory() {
  const navigate = useNavigate();
  const user = Cookies.get("role");
  const ID = Cookies.get("user_id");

  const { priceListId } = useParams();
  const [history, setHistory] = useState([]);
  const [priceList, setPriceList] = useState({});

  const fetchPLHistory = () => {
    axios
      .get(`${config.base_url}/fetch_pl_history/${priceListId}/`)
      .then((res) => {
        console.log("PL HIST=", res);
        if (res.data.status) {
          var pl = res.data.priceList;
          var hist = res.data.history;
          setPriceList(pl);
          setHistory([]);
          hist.map((i) => {
            setHistory((prevState) => [...prevState, i]);
          });
        }
      })
      .catch((err) => {
        console.log("ERROR=", err);
        if (!err.response.data.status) {
          Swal.fire({
            icon: "error",
            title: `${err.response.data.message}`,
          });
        }
      });
  };

  useEffect(() => {
    fetchPLHistory();
  }, []);

  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper d-flex">
        <Sidebar />
        <div
          className="main-panel px-3 py-2"
          style={{ background: "#a9a9a961", maxWidth:"100vw" }}
        >

        <Link
          className="d-flex justify-content-end p-2"
          style={{ cursor: "pointer" }}
          to={`/ViewPriceList/${priceListId}/`}
        >
          <i
            className="fa fa-times-circle text-white"
            style={{ fontSize: "1.2rem" }}
          ></i>
        </Link>

          <div className="content-wrapper">
            <div className="body-wrapper p-3" style={{ minHeight: "100vh" }}>
              <div className="container-fluid">
                <div className="card radius-15 h-20 mt-3">
                  <div className="row w-100">
                    <div className="col-md-12">
                    <br></br>
                    <center>
                        <h3
                        className="card-title"
                        style={{ textTransform: "Uppercase" }}
                        >
                        {priceList.name}
                        </h3>
                        {priceList.status == "Inactive" ? (
                        <h6
                            className="blinking-text"
                            style={{ color: "red", width: "140px", fontWeight: "bold" }}
                        >
                            INACTIVE
                        </h6>
                        ) : (
                        <h6
                            style={{
                            width: "140px",
                            color: "green",
                            fontWeight: "bold",
                            }}
                        >
                            ACTIVE
                        </h6>
                        )}
                    </center>
                    </div>
                  </div>
                </div>
                <br></br>
                <div className="card radius-15">
                  <div className="card-body">
                    <div className="card-title"></div>

                    <div id="history">
                    <center>
                        <h3 className="mt-3 text-uppercase">
                        {priceList.name} - TRANSACTIONS
                        </h3>
                    </center>
                    <div className="table-responsive px-2">
                        <table className="table table-bordered">
                        <thead>
                            <tr>
                            <th className="text-center">SL NO.</th>
                            <th className="text-center">DATE</th>
                            <th className="text-center">ACTION</th>
                            <th className="text-center">DONE BY</th>
                            </tr>
                        </thead>
                        <tbody>
                        {history &&
                        history.map((h, index) => (
                            <tr>
                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                            <td style={{ textAlign: "center" }}>{h.date}</td>
                            {h.action == "Created" ? (
                                <td className="text-success text-center">
                                {h.action}
                                </td>
                            ) : (
                                <td className="text-warning text-center">
                                {h.action}
                                </td>
                            )}
                            <td style={{ textAlign: "center" }}>{h.name}</td>
                            </tr>
                        ))}
                        </tbody>
                        </table>
                    </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PriceListHistory;
